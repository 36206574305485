export const SPECS = {
  CustomProductUrlInCartOOI: 'specs.stores.CustomProductUrlInCartOOI',
  CashierExpressNavigateToThankYouPageWhen1ShippingMethod:
    'specs.stores.CashierExpressNavigateToThankYouPageWhen1ShippingMethod',
  DisplayPickupInTotalSummary: 'specs.stores.DisplayPickupInTotalSummary2',
  CashierExpressAddUserInputsToProductName: 'specs.stores.CashierExpressAddUserInputsToProductName',
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
} as const;
